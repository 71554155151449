<template>
  <div class="cpt-MMC_Gimbal_FF6">
    <div v-interact class="hd">
      <div class="title">{{$t('flightApplication.喷火器')}}</div>
    </div>
    <div class="bd">
      <div class="form-wrap">
        <div class="form-item">
          <div class="label-box">{{$t('flightApplication.保险')}}</div>
          <div class="input-box">
            <el-switch v-model="safety_switch_state" size="mini" />
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">{{$t('flightApplication.喷火')}}</div>
          <div class="input-box">
            <el-switch v-model="fire_status" size="mini" @change="handle_fire_ctrl" />
          </div>
        </div>
      </div>
      <div class="rocker-wrap">
        <div class="title-box">
          <div class="title">{{$t('operation.Operation')}}</div>
        </div>
        <div ref="rocker" class="rocker">
          <div class="shangUp" @click="fangxiang('up')" />
          <div class="xaiUp" @click="fangxiang('down')" />
          <div class="zuoUp" @click="fangxiang('right')" />
          <div class="youUp" @click="fangxiang('left')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MMC_Gimbal_FF6 } from '../utils';
export default {
  data() {
    return {
      timer: null,
      radio: 1,
      safety_switch_state: false,
      fire_status: false
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.init_rocker();
    });
  },
  methods: {
    handle_fire_ctrl(value) {
      if (!this.safety_switch_state) {
        return (this.fire_status = false);
      }
      const buff = MMC_Gimbal_FF6.fire_ctrl(1);
      this.commit_directive(buff);
    },
    commit_directive(buffer) {
      this.$emit('directive', buffer);
    },
    fangxiang(type) {
      let buffer = null;
      switch (type) {
        case 'up':
          buffer = MMC_Gimbal_FF6.gimbal_yaw_ctrl(0);
          this.commit_directive(buffer);
          setTimeout(() => {
            buffer = MMC_Gimbal_FF6.gimbal_pitch_ctrl(1);
            this.commit_directive(buffer);
          }, 50);

          break;
        case 'right':
          buffer = MMC_Gimbal_FF6.gimbal_pitch_ctrl(0);
          this.commit_directive(buffer);
          setTimeout(() => {
            buffer = MMC_Gimbal_FF6.gimbal_yaw_ctrl(-1);
            this.commit_directive(buffer);
          }, 50);
          break;
        case 'left':
          buffer = MMC_Gimbal_FF6.gimbal_pitch_ctrl(0);
          this.commit_directive(buffer);
          setTimeout(() => {
            buffer = MMC_Gimbal_FF6.gimbal_yaw_ctrl(1);
            this.commit_directive(buffer);
          }, 50);
          break;
        case 'down':
          buffer = MMC_Gimbal_FF6.gimbal_yaw_ctrl(0);
          this.commit_directive(buffer);
          setTimeout(() => {
            buffer = MMC_Gimbal_FF6.gimbal_pitch_ctrl(-1);
            this.commit_directive(buffer);
          }, 50);
      }
      this.timer = setTimeout(() => {
        clearTimeout(this.timer);
        buffer = MMC_Gimbal_FF6.gimbal_pitch_ctrl(0);
        this.commit_directive(buffer);
        buffer = MMC_Gimbal_FF6.gimbal_yaw_ctrl(0);
        this.commit_directive(buffer);
      }, 500);
    },
    init_rocker() {
      let buffer = null;
      joystick({
        // zone: this.$refs["rocker"],
        // mode: "static",
        // position: { left: "50%", top: "50%" },
        // color: "#9bacbe",
        onstart: (type) => {
          switch (type) {
            case 'up':
              buffer = MMC_Gimbal_FF6.gimbal_yaw_ctrl(0);
              this.commit_directive(buffer);
              setTimeout(() => {
                buffer = MMC_Gimbal_FF6.gimbal_pitch_ctrl(1);
                this.commit_directive(buffer);
              }, 50);

              break;
            case 'right':
              buffer = MMC_Gimbal_FF6.gimbal_pitch_ctrl(0);
              this.commit_directive(buffer);
              setTimeout(() => {
                buffer = MMC_Gimbal_FF6.gimbal_yaw_ctrl(-1);
                this.commit_directive(buffer);
              }, 50);
              break;
            case 'left':
              buffer = MMC_Gimbal_FF6.gimbal_pitch_ctrl(0);
              this.commit_directive(buffer);
              setTimeout(() => {
                buffer = MMC_Gimbal_FF6.gimbal_yaw_ctrl(1);
                this.commit_directive(buffer);
              }, 50);
              break;
            case 'down':
              buffer = MMC_Gimbal_FF6.gimbal_yaw_ctrl(0);
              this.commit_directive(buffer);
              setTimeout(() => {
                buffer = MMC_Gimbal_FF6.gimbal_pitch_ctrl(-1);
                this.commit_directive(buffer);
              }, 50);
          }
        },
        onend: () => {
          buffer = MMC_Gimbal_FF6.gimbal_pitch_ctrl(0);
          this.commit_directive(buffer);
          buffer = MMC_Gimbal_FF6.gimbal_yaw_ctrl(0);
          this.commit_directive(buffer);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.cpt-MMC_Gimbal_FF6 {
  position: absolute;
  top: 0px;
  right: 0;
  /* background: center url("~@/assets/images/observe/fckernel/mount/01_bg.png")
    no-repeat; */
  background: rgba(0, 23, 79, 0.7);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35), inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 10px;
  background-size: 100% 100%;
  width: 500px;
  height: 290px;
  box-sizing: border-box;
  padding: 10px 20px;
  .hd {
    .title {
      font-size: 18px;
      color: #08c2d1;
      font-weight: bold;
      line-height: 2;
    }
  }
  .bd {
    display: flex;
    justify-content: space-between;
    .form-wrap {
      min-width: 245px;
      flex-shrink: 0;
      .form-item {
        display: flex;
        align-items: center;
        height: 40px;
        .label-box {
          color: #cbd0eb;
          width: 75px;
        }
        .input-box {
          display: flex;
          align-items: center;
          .icon-box {
            [class^='el-icon-'] {
              font-size: 30px;
              color: #dce9ff;
            }
          }
        }
      }
    }
    .rocker-wrap {
      flex: 1;
      .title-box {
        .title {
          color: #cbd0eb;
        }
      }
      .rocker {
        margin: 10px auto 0;
        width: 150px;
        height: 150px;
        position: relative;
        margin: 0 auto;
        background: center url('~@/assets/images/accident/caozuo.png') no-repeat;
        background-size: 100% 100%;
        .shangUp {
          position: absolute;
          left: 37%;
          top: 4px;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }
        .xaiUp {
          position: absolute;
          left: 37%;
          bottom: 4px;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }
        .zuoUp {
          position: absolute;
          left: 4px;
          top: 37%;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }
        .youUp {
          position: absolute;
          right: 4px;
          top: 37%;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }
      }
    }
  }
}
::v-deep {
  .el-select {
    width: 165px;
    margin-right: 5px;
    .el-input {
      .el-input__inner {
        background: #000000;
        border: 1px solid #08c2d1;
        color: #dce9ff;
      }
    }
  }
  .el-input {
    width: 165px;
    margin-right: 5px;
    .el-input__inner {
      background: #000000;
      border: 1px solid #08c2d1;
      color: #dce9ff;
    }
  }
  .el-radio-group {
    .el-radio {
      .el-radio__input {
        &.is-checked {
          .el-radio__inner {
            border-color: #08c2d1;
            background: #08c2d1;
          }
        }
      }
      .el-radio__label {
        color: #cbd0eb;
      }
    }
  }
  .el-button {
    background: #2aefed;
    color: #000;
    border: none;
  }
}
</style>
